import * as i0 from '@angular/core';
import { Injectable, inject, APP_INITIALIZER, NgModule } from '@angular/core';
import * as i3 from '@spartacus/core';
import { Config, FeatureModulesService, RoutingService, provideDefaultConfig } from '@spartacus/core';
import * as i2 from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { take, switchMap } from 'rxjs/operators';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class SmartEditConfig {
  static {
    this.ɵfac = function SmartEditConfig_Factory(t) {
      return new (t || SmartEditConfig)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SmartEditConfig,
      factory: function SmartEditConfig_Factory(t) {
        let r = null;
        if (t) {
          r = new (t || SmartEditConfig)();
        } else {
          r = i0.ɵɵinject(Config);
        }
        return r;
      },
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SmartEditConfig, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useExisting: Config
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const SMART_EDIT_FEATURE = 'smartEdit';

/**
 * The SmartEditLauncherService is used to check whether Spartacus is launched inside Smart Edit;
 * it also gets cmsTicketId sent from Smart Edit.
 */
class SmartEditLauncherService {
  get cmsTicketId() {
    return this._cmsTicketId;
  }
  constructor(config, location, scriptLoader) {
    this.config = config;
    this.location = location;
    this.scriptLoader = scriptLoader;
    this.featureModulesService = inject(FeatureModulesService);
  }
  /**
   * load webApplicationInjector.js first when Spartacus launched inside SmartEdit
   */
  load() {
    if (this.isLaunchedInSmartEdit()) {
      this.featureModulesService.resolveFeature(SMART_EDIT_FEATURE).subscribe();
      this.scriptLoader?.embedScript({
        src: 'assets/webApplicationInjector.js',
        params: undefined,
        attributes: {
          id: 'text/smartedit-injector',
          'data-smartedit-allow-origin': this.config.smartEdit?.allowOrigin
        }
      });
    }
  }
  /**
   * Indicates whether Spartacus is launched in SmartEdit
   */
  isLaunchedInSmartEdit() {
    const path = this.location.path().split('?')[0];
    const params = this.location.path().split('?')[1];
    const cmsToken = params?.split('&').find(param => param.startsWith('cmsTicketId='));
    this._cmsTicketId = cmsToken?.split('=')[1];
    return path.split('/').pop() === this.config.smartEdit?.storefrontPreviewRoute && !!this._cmsTicketId;
  }
  static {
    this.ɵfac = function SmartEditLauncherService_Factory(t) {
      return new (t || SmartEditLauncherService)(i0.ɵɵinject(SmartEditConfig), i0.ɵɵinject(i2.Location), i0.ɵɵinject(i3.ScriptLoader));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SmartEditLauncherService,
      factory: SmartEditLauncherService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SmartEditLauncherService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: SmartEditConfig
  }, {
    type: i2.Location
  }, {
    type: i3.ScriptLoader
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const defaultSmartEditConfig = {
  smartEdit: {
    storefrontPreviewRoute: 'cx-preview',
    allowOrigin: 'localhost:9002'
  }
};
class CmsTicketInterceptor {
  constructor(service) {
    this.service = service;
    this.routingService = inject(RoutingService);
  }
  intercept(request, next) {
    const cmsTicketId = this.service.cmsTicketId;
    if (!cmsTicketId) {
      return next.handle(request);
    }
    if (request.url.includes('/productList')) {
      return this.setRequestForProductListPage(request, next, cmsTicketId);
    }
    if (request.url.includes('/cms/') || request.url.includes('/products/')) {
      request = request.clone({
        setParams: {
          cmsTicketId
        }
      });
    }
    return next.handle(request);
  }
  setRequestForProductListPage(request, next, cmsTicketId) {
    return this.routingService.getPageContext().pipe(take(1), switchMap(pageContext => {
      request = request.clone(!!pageContext.id ? {
        setParams: {
          cmsTicketId,
          categoryCode: pageContext.id
        }
      } : {
        setParams: {
          cmsTicketId
        }
      });
      return next.handle(request);
    }));
  }
  static {
    this.ɵfac = function CmsTicketInterceptor_Factory(t) {
      return new (t || CmsTicketInterceptor)(i0.ɵɵinject(SmartEditLauncherService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CmsTicketInterceptor,
      factory: CmsTicketInterceptor.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CmsTicketInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: SmartEditLauncherService
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const interceptors = [{
  provide: HTTP_INTERCEPTORS,
  useExisting: CmsTicketInterceptor,
  multi: true
}];

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
function smartEditFactory(smartEditLauncherService) {
  const isReady = () => {
    smartEditLauncherService.load();
  };
  return isReady;
}
class SmartEditRootModule {
  static {
    this.ɵfac = function SmartEditRootModule_Factory(t) {
      return new (t || SmartEditRootModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SmartEditRootModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [...interceptors, provideDefaultConfig(defaultSmartEditConfig), {
        provide: APP_INITIALIZER,
        useFactory: smartEditFactory,
        deps: [SmartEditLauncherService],
        multi: true
      }]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SmartEditRootModule, [{
    type: NgModule,
    args: [{
      providers: [...interceptors, provideDefaultConfig(defaultSmartEditConfig), {
        provide: APP_INITIALIZER,
        useFactory: smartEditFactory,
        deps: [SmartEditLauncherService],
        multi: true
      }]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CmsTicketInterceptor, SMART_EDIT_FEATURE, SmartEditConfig, SmartEditLauncherService, SmartEditRootModule, smartEditFactory };
